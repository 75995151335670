.bottom-nav {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Droid Sans, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-top: 1px solid #e0e0e0;
  background-color: #FFFFFF;
  z-index: 1000;
  -webkit-tap-highlight-color: transparent;
}

@media print {
  .bottom-nav {
    display: none;
  }
}

.bottom-nav__action {
  padding-right: 12px;
  padding-left: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  min-width: 56px;
  max-width: 144px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  color: #757575;
  text-decoration: none;
}

.bottom-nav__action:not(:last-child) {
  margin-right: 12px;
}

.bottom-nav__action--active {
  padding-top: 6px;
  color: #FFC107;
}

.bottom-nav__icon {
  display: block;
  width: 1em;
  height: 1em;
  font-size: 24px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4px;
}

.bottom-nav__icon path,
.bottom-nav__icon polygon,
.bottom-nav__icon rect {
  fill: currentColor;
}

.bottom-nav__icon circle {
  stroke: currentColor;
  stroke-width: 1;
}

.bottom-nav__label {
  font-weight: 400;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  display: block;
  font-size: 12px;
}

.bottom-nav__action--active .bottom-nav__label {
  font-size: 14px;
}

/*# sourceMappingURL=scss.css.map */
