.tab-content {
    padding: 0 !important;
}

.previous.disabled, .next.disabled {
    display: none;
}

.separator {
    width: 64px;
    height: 2px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.card-header {
    padding-top: calc((100% - 30px) * 0.5625);
    position: relative;
}

.card-header h2 {
    position: absolute;
    bottom: 15px;
    left: 15px;
    font-weight: 700;
    word-break: break-word;
    right: 15px;
}

.card-body.padding {
    padding: 0px 15px !important;
}

.chip {
    display: inline-block;
    height: 24px;
    border-radius: 12px;
    background: #EEEEEE;
    line-height: 24px;
    font-size: 12px;
    color: black;
    padding-right: 10px;
    margin-right: 15px;
}

.chip-image {
    float: left;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    margin-right: 5px;
}

.card {
    margin-bottom: 20px;
}

.card-body a {
    padding: 15px;
    color: #757575 !important;
    text-align: left;
}

.card-body a i {
    font-size: 20px;
}

.card-body.border-top {
    border-top: 1px solid #EEEEEE;
}

.uppercase {
    text-transform: uppercase;
}

#main {
    padding: 0px 20px 60px 20px !important;
}
.bottom-nav__action--active {
    color: #FFC107 !important;
}

#header {
    background-size: auto 50% !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
}

.waves-effect {
    display: inherit;
}
