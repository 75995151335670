#header {
    padding-left: 0;
    padding-top: 0;
    padding-right: 0;
    padding-top: 56.25% !important;
    margin-bottom: 10px;
    padding-top: 56.25%;
}

/* Small Devices, Tablets */
@media  only screen and (min-width : 768px) {
    #header {
        padding-top: 33.33% !important;
    }
}

/* Medium Devices, Desktops */
@media  only screen and (min-width : 992px) {
    #header {
        padding-top: 25% !important;
    }
}

/* Large Devices, Wide Screens */
@media  only screen and (min-width : 1200px) {
    #header {
        padding-top: 20% !important;
    }
}

body {
    padding-right: 0px !important;
}

[v-cloak] {
    display: none;
}

.dropdown-menu a {
    cursor: pointer;
}

.separator {
    background-color: rgb(252, 101, 34) !important;
}

.event-date {
    color: #757575;
}
